//import angular from 'angular';
import * as _ from 'underscore';
import BrowserSvc from '../../../services/browserSvc';
import OcConfigSvc from '../../../services/ocConfigSvc';


    angular.module('app.component')
        .controller('componentReceiveErroredCtrl', [
            '$scope', 'WindowFactory', 'amtXlatSvc', 'componentUrls', 'browserSvc',
            'notifySvc', '$filter', 'componentReceiveFactory',
            'ocConfigSvc', 'ocSecuritySvc', 'confirmSvc', '$timeout', 'enums', 'errorReporter', 'dataBroker',
            function ($scope, WindowFactory: IWindowFactory, xlatSvc: IAmtXlatSvc, componentUrls, browserSvc: BrowserSvc,
                notifySvc: INotifySvc, $filter: ng.IFilterService, componentReceiveFactory, ocConfigSvc: OcConfigSvc, ocSecuritySvc: IOcSecuritySvc,
                confirmSvc: IConfirmSvc, $timeout: ng.ITimeoutService, enums, errorReporter: IErrorReporter, dataBroker: IDataBroker) {

                var vm = this;

                vm.isMobile = browserSvc.isMobile;

                // controls        
                vm.errorGrid = {};
                vm.errorDisplay = {};

                // injector mappings                
                vm.apiUrls = componentUrls;
                vm.enums = enums;
                vm.config = ocConfigSvc;

                // variables
                vm.status = enums.screenStatus.loading;

                // collections
                vm.erroredReceivals = [];

                // Binding of data to the error grid
                vm.onDatabound = function (event) {

                    vm.processing = true; // Should be true anyway, but just make sure.

                    // Make all the rows red background
                    if (event) {

                        var rows = event.sender.tbody.children();

                        for (var i = 0; i < rows.length; i++) {

                            var row = $(rows[i]);

                            row.addClass("bg-danger"); // light red background
                        }
                    }

                    vm.processing = false;

                    $timeout();
                };

                // adjusts details displayed on the errored received grid (desktop)
                $scope.rowParseFunction = function (response) {

                    var data = response.result;

                    data.forEach(function (item) {

                        var components = item.data.components;

                        item.cost = "";

                        var first;

                        item.componentType = components[0].equipmentTypeDescription;

                        var conditionType = null;

                        if (vm.componentConditions.length > 0) {
                            conditionType = _.find(vm.componentConditions, function (c) {
                                return c.id === (components[0].conditionType ? components[0].conditionType : "Pending");
                            });
                        }

                        item.conditionType = conditionType ? conditionType : { name: item.conditionType };

                        item.errorCount = xlatSvc.xlat((item.errorCount > 1 ? "component.errorCount_plural" : "component.errorCount"), item.errorCount);

                        var refDate;

                        //Pending receive path which is a 3rd party receive (which is not being used by at the moment)
                        //not to be confused with pending receives (which is pretty much every row)
                        if (item.conditionType.id === "Pending") {

                            first = _.first(components);
                            refDate = first.receiveDate ? first.receiveDate.getTime() : null;

                            // if multiple dates, show 'multiple'
                            if (components.length > 1 && !_.every(components, function (component) {
                                return (component.receiveDate ? component.receiveDate.getTime() === refDate : false);
                            })) {
                                item.receiveDate = new Date();
                                item.receiveDate_display = xlatSvc.xlat("component.multiple_label");
                            } else {
                                item.receiveDate = components[0].receiveDate;
                                item.receiveDate_display = $filter("date")(components[0].receiveDate, "short");
                            }

                            item.purchaseOrder = "";

                            // if multiple specifications, show 'multiple'
                            if (!_.every(components, function (component) {
                                return component.specificationName == first.specificationName;
                            })) {
                                item.specification = xlatSvc.xlat("component.multiple_label");
                            } else { item.specification = first.specificationName; }

                            // if multiple component types, show 'multiple'
                            if (!_.every(components, function (component) {
                                return component.equipmentTypeId == first.equipmentTypeId;
                            })) {
                                item.componentType = xlatSvc.xlat("component.multiple_label");
                            } else {
                                if (!item.componentType) {
                                    item.componentType = _.find(vm.componentTypes, function (c) { return c.id == first.equipmentTypeId; }).description;
                                }
                            }

                            // if multiple components, show 'multiple' as cost
                            if (components.length > 1) {
                                item.cost = xlatSvc.xlat("component.multiple_label");
                            } else {
                                item.cost = (components[0].costDetails ? vm.currencySymbol + components[0].costDetails.amount.toFixed(vm.currencyDecimalPlaces) : null);
                            }

                            if (components[0].eventDetails.movementDetails && components[0].eventDetails.movementDetails.receiveDetails) {

                                item.purchaseOrder = components[0].eventDetails.movementDetails.receiveDetails.purchaseOrderNumber;

                                if (!components.every(c => !c.eventDetails.movementDetail || !c.eventDetails.movementDetail.receiveDetails || item.purchaseOrder.toUpperCase() === c.eventDetails.movementDetails.receiveDetails.purchaseOrderNumber.toUpperCase())) {
                                    item.purchaseOrder = xlatSvc.xlat("component.multiple_label");
                                }
                            }

                        } else {

                            // if there are no event details fill the structure so the grid still shows, and I don't have a bundle of null checks below

                            components.forEach(function (c) {
                                if (!c.eventDetails) {
                                    c.eventDetails = {
                                        eventDate: null,
                                        costDetails: { amount: 0 },
                                        movementDetails: {
                                            receiveDetails: {
                                                purchaseOrderNumber: "",
                                                purchaseOrderId: null,
                                                invoiceReference: ""
                                            }
                                        }
                                    };
                                } else if (!c.eventDetails.costDetails) {
                                    c.eventDetails.costDetails = { amount: 0 };
                                }

                                if (!c.eventDetails.movementDetails) {
                                    c.eventDetails.movementDetails = {
                                        receiveDetails: {
                                            purchaseOrderNumber: "",
                                            purchaseOrderId: null,
                                            invoiceReference: ""
                                        }
                                    }
                                }

                                if (!c.conditionType) {
                                    c.conditionType = item.conditionType.id;
                                }
                            });

                            try {

                                first = _.first(components);
                                refDate = first.eventDetails.eventDate ? first.eventDetails.eventDate.getTime() : null;

                                // if multiple dates, show 'multiple'
                                if (components.length > 1 && !_.every(components, function (component) {
                                    return (component.eventDetails && component.eventDetails.eventDate) ? component.eventDetails.eventDate.getTime() === refDate : false;
                                })) {
                                    item.receiveDate = new Date();
                                    item.receiveDate_display = xlatSvc.xlat("component.multiple_label");
                                } else {
                                    item.receiveDate = components[0].eventDetails.eventDate;
                                    item.receiveDate_display = $filter("date")(components[0].eventDetails.eventDate, "short");
                                }

                                // if multiple specifications, show 'multiple'
                                if (!_.every(components, function (component) {
                                    return component.specificationName == first.specificationName;
                                })) {
                                    item.specification = xlatSvc.xlat("component.multiple_label");
                                } else { item.specification = first.specificationName; }

                                // if multiple component types, show 'multiple'
                                if (!_.every(components, function (component) {
                                    return component.equipmentTypeId == first.equipmentTypeId;
                                })) {
                                    item.componentType = xlatSvc.xlat("component.multiple_label");
                                } else {
                                    if (!item.componentType) {
                                        item.componentType = _.find(vm.componentTypes, function (c) { return c.id == first.equipmentTypeId; }).description;
                                    }
                                }

                                // if multiple components, show 'multiple' as cost
                                if (components.length > 1) {
                                    item.cost = xlatSvc.xlat("component.multiple_label");
                                } else {
                                    item.cost = (components[0].eventDetails && components[0].eventDetails.costDetails ? vm.currencySymbol + components[0].eventDetails.costDetails.amount.toFixed(vm.currencyDecimalPlaces) : null);
                                }
                                                                
                                item.purchaseOrderId = components[0].eventDetails.movementDetails.receiveDetails.purchaseOrderId;
                                item.purchaseOrderNumber = components[0].eventDetails.movementDetails.receiveDetails.purchaseOrderNumber;
                                item.purchaseOrder = item.purchaseOrderNumber ? item.purchaseOrderNumber : ""; //TODO: ensure that the pending order has this when used with a purchaseOrderId?


                                item.invoiceReference = components[0].eventDetails.movementDetails.receiveDetails.invoiceReference;

                            } catch (ex) {
                                errorReporter.logError(ex); // tell someone, but don't block the grid
                            }
                        }

                        if (!item.receiveDate_display) {
                            item.receiveDate_display = "---";
                        }
                    });

                    response.result = $filter("orderBy")(response.result, "receiveDate", true);

                    return response;
                };

                vm.getComponentTypes = function () {

                    // get the component types
                    return dataBroker.getComponentTypes(vm.config.user.site.id).then(function (response) {
                        vm.componentTypes = response.result;
                    }).catch(function (error) {
                        // Reports as "TranslatedResource : error.message"                
                        errorReporter.logError(error, xlatSvc.xlat("component.componentTypeRetrieveFail"));
                    });
                };

                // Load Errored Receivals
                // This is the list of component receives held in error in the server (for Desktop)
                vm.loadErroredReceivals = function () {

                    vm.status = "loading";
                    vm.currencySymbol = vm.config.user.site.currency.symbol;
                    vm.currentDecimalPlaces = vm.config.user.site.currency.decimalPlaces;

                    dataBroker.getPendingReceivals('components', vm.config.user.site.id).then(function (result) {

                        if (result) {

                            vm.erroredReceivals = result.data;

                            let errCount = vm.erroredReceivals ? vm.erroredReceivals.length : 0;

                            vm.erroredReceivalsTitle = xlatSvc.xlat(errCount == 0 ? "component.noErroredReceivals" : errCount == 1 ? "component.erroredReceivalsCount" : "component.erroredReceivalsCount_plural", errCount);

                            // Makes the page update once data is received/populated.                    
                            vm.status = "ready";

                            $timeout(function () {
                                if (vm.errorGrid && vm.errorGrid.refresh) {
                                    vm.errorGrid.refresh();
                                }
                            });
                        }
                    }).catch(function (error) {
                        errorReporter.logError(error, xlatSvc.xlat("component.erroredReceivalsFail"));
                        vm.status = "error";
                    });
                };

                vm.setHeaderHeight = function (height, offset) {
                    vm.gridTop = height + offset;
                };

                $scope.openReceival = function (item) {
                    WindowFactory.openItem({
                        component: 'component-receive',
                        caption: xlatSvc.xlat('component.receiveTitle'),
                        initParams: {
                            pendingReceive: item
                        },
                        canClose: true,
                        windowRelatedRecordId: item.id,
                        onDataChangeHandler: () => {
                            vm.errorGrid.refresh();
                            if (vm.canViewErroredReceives) {
                                vm.loadErroredReceivals();
                            };
                        },
                        footerOff: true,
                        width: 1300,
                        height: 820
                    });
                };

                vm.startReceiving = function () {
                    WindowFactory.openItem({
                        component: 'component-receive',
                        caption: xlatSvc.xlat('component.receiveTitle'),
                        initParams: undefined,
                        canClose: true,
                        windowRelatedRecordId: uuid(),
                        footerOff: true,
                        width: 1300,
                        height: 820
                    });
                };

                // Delete an errored component receive (Desktop)
                $scope.deleteErroredReceival = function (dataItem) {
                    var index = _.findIndex(vm.erroredReceivals, function (element) {
                        return element.id === dataItem.id;
                    });

                    if (index > -1) {
                        confirmSvc.confirmMessage("component.confirmDeleteErroredReceivalTitle", "component.confirmDeleteErroredReceivalContent")
                            .then(function (result) {
                                if (result) {

                                    vm.processing = true;

                                    dataBroker.deletePendingReceival(dataItem.id).then(function () {
                                        vm.erroredReceivals.splice(index, 1);
                                        vm.errorGrid.refresh();
                                        notifySvc.success(xlatSvc.xlat("component.pendingReceivalDeleted"));
                                    }).finally(function () {
                                        vm.processing = false;
                                    });
                                }
                            });
                    }
                };

                function init() {

                    vm.canViewErroredReceives = ocSecuritySvc.isAuthorised("Security.Components.Receive.Errored", AccessTypes.readOnly);

                    vm.getComponentTypes().then(function () {
                        vm.componentConditions = componentReceiveFactory.getComponentConditions(null, true,
                            vm.config.user.site.settings.receive.allowThirdPartyComponents, 'All');

                        if (vm.canViewErroredReceives) {
                            vm.loadErroredReceivals();
                        }
                    });
                };

                init();
            }
        ]);
