//import angular from 'angular';
import { TRemoveComponentData } from './Remove';
import tmpl from './RemoveDetails.html';
import AdminUrls from '../../../admin/adminUrls';


class RemoveDetailsCtrl implements ng.IController {

    form: ng.IFormController;

    attachToApp: boolean;

    assembly: boolean;
    data: TRemoveComponentData;
    removeIndividually: boolean;
    processing: boolean;

    moveToStatusTypes = [];
    removalReasons = [];
    damageCauses = [];

    damageLocations = [];
    damageLocationFullList = []; // the list of all damage locations for the component type

    damageSources = [];
    damageSourceFullList = []; // the list of all damage sources for the component type

    repairers = [];

    availableDestinations = [];
    locations = [];

    showOtherDestination = false;
    showOtherRepairer = false;

    creditAmountLabel = this.amtXlatSvc.xlat('maintenanceSession.creditAmountSymbol', this.ocConfigSvc.user.site.currency.symbol);
    enforceLocation = this.ocConfigSvc.user.site.settings.tyreMaintenance.storeLocation;

    static $inject = ['$scope', 'amtXlatSvc', 'ocConfigSvc', 'amtConstants', '$timeout', 'dataBroker', 'errorReporter', 'adminUrls', 'amtCommandQuerySvc'];

    constructor(private $scope: ng.IScope, private amtXlatSvc: IAmtXlatSvc, private ocConfigSvc: IOcConfigSvc, private amtConstants: IAmtConstants,
        private $timeout: ng.ITimeoutService, private dataBroker: IDataBroker, private errorReporter: IErrorReporter, private adminUrls: AdminUrls, private amtCommandQuerySvc: IAmtCommandQuerySvc) {

        this.$scope.$watch(() => this.data.selectedDamageCause, (newVal, oldVal) => {
            this.onDamageCauseChanged(newVal, oldVal);
        });

        this.$scope.$watch(() => this.data.destination, () => {
            if (this.data.destination) {
                this.showOtherDestination = this.data.destination.key === this.amtConstants.emptyGuid;

                if (!this.showOtherDestination)
                    this.data.otherDestination = null;
            }
        }, true);

        this.$scope.$watch(() => this.data.repairer, () => {
            if (this.data.repairer) {
                this.showOtherRepairer = this.data.repairer.key === this.amtConstants.emptyGuid;

                if (!this.showOtherRepairer)
                    this.data.otherRepairer = null;
            }
        }, true);

        this.$scope.$watch(() => this.data.selectedMoveTo, async (newVal, oldVal) => {

            if (newVal) {

                this.data.canRefit = newVal.name.toLowerCase() === StatusTypeName.spare;

                await this.getLocations();

                this.$timeout(); // make sure UI is updated
            }

            if (!oldVal || (newVal && (oldVal && newVal.id !== oldVal.id))) {

                this.showOtherDestination = false;

                this.data.selectedDamageCause = null;
                this.data.selectedDamageLocation = null;
                this.data.selectedDamageSource = null;
                this.data.instant = null;
                this.data.tyreBurst = null;
                this.data.tyreExplosion = null;
                this.data.selectedRemovalReason = null;
                this.data.selectedLocation = null;
                this.data.destination = null;
                this.data.otherDestination = null;
                this.data.creditAmount = null;
                this.data.repairer = null;
                this.data.otherRepairer = null;
            }
        });
    }

    async $onInit() {

        this.processing = true;

        if (angular.isUndefined(this.attachToApp)) {
            this.attachToApp = false;
        }

        try {
            await this.loadComponentReferenceData();

            this.$timeout(); // make sure UI is updated
        } finally {
            this.processing = false;
        }
    }

    onDamageCauseChanged(newVal, oldVal) {

        if (!this.data.selectedDamageCause)
            return;

        if (!newVal || (oldVal && newVal.id !== oldVal.id)) {
            this.data.selectedDamageLocation = null;
            this.data.selectedDamageSource = null;
            this.data.instant = null;
            this.data.tyreBurst = null;
            this.data.tyreExplosion = null;
        }

        this.damageLocations = this.damageLocationFullList.filter(l => l.damageCauseId === this.data.selectedDamageCause.id);
        this.damageSources = this.damageSourceFullList.filter(s => s.damageCauseId === this.data.selectedDamageCause.id);
    }

    async getRemovalReasons() {

        if (!this.data.componentTypeId)
            console.warn('no component type ID in call to get remove reasons.');

        try {
            let getRemovalReasonCriteria: IRemovalReasonCriteria = {
                componentTypeId: this.data.componentTypeId,
                atDate: this.data.sessionDate
            };

            let removalReasons = await this.dataBroker.getRemovalReasons(getRemovalReasonCriteria);

            this.removalReasons = removalReasons.result;

            if (this.data.selectedRemovalReason) {
                let filteredRemovalReason = this.removalReasons.find(r => r.id === this.data.selectedRemovalReason.id);

                if (filteredRemovalReason)
                    this.data.selectedRemovalReason = filteredRemovalReason;
            }

            // manually filter for active only so we can hide when there are none to select from
            this.removalReasons = this.removalReasons.filter(r => r.isActive);

        } catch (error) {
            this.errorReporter.logError(error, 'RemoveDetails-GetRemovalReasons');
        }
    }

    async getLocations() {

        try {
            let getLocationCriteria: ILocationCriteria = {
                siteIds: [this.ocConfigSvc.user.site.id],
                date: this.data.sessionDate,
                statusTypeIds: [this.data.selectedMoveTo.id]
            };

            let locations = await this.dataBroker.getLocations(getLocationCriteria);

            this.locations = locations.result;

            if (this.data.selectedLocation) {
                let filteredLocation = this.locations.find(l => l.key === (this.data.selectedLocation.id || this.data.selectedLocation.key));

                if (filteredLocation)
                    this.data.selectedLocation = filteredLocation;
            }

        } catch (error) {
            this.errorReporter.logError(error, 'RemoveDetails-GetLocations');
        }
    }

    async getRepairers() {

        try {
            let getRepairerCriteria: IRepairerCriteria = {
                siteId: this.ocConfigSvc.user.site.id,
                equipmentTypeIds: [this.data.componentTypeId],
                includeOther: this.ocConfigSvc.user.site.settings.referenceData.allowOther.repairer
            };

            // repairers            
            let repairers = await this.dataBroker.getRepairers(getRepairerCriteria);

            this.repairers = repairers.result;

            if (this.data.repairer) {
                if (this.data.repairer.repairerOther)
                    this.data.otherRepairer = this.data.repairer.repairerOther;

                let filteredRepairer = this.repairers.find(r => r.key === (this.data.repairer.id || this.data.repairer.key));

                if (filteredRepairer)
                    this.data.repairer = filteredRepairer;
            }

        } catch (error) {
            this.errorReporter.logError(error, 'RemoveDetails-GetRepairers');
        }
    }

    async getDamageCauses() {

        try {
            let getDamageCauseCriteria: IDamageCauseCriteria = {
                componentTypeId: this.data.componentTypeId,
                atDate: this.data.sessionDate
            };

            let damageCauses = await this.dataBroker.getDamageCauses(getDamageCauseCriteria);

            this.damageCauses = damageCauses.result;

            if (this.data.selectedDamageCause) {
                let filteredDamageCause = this.damageCauses.find(dc => dc.id === this.data.selectedDamageCause.id);

                if (filteredDamageCause)
                    this.data.selectedDamageCause = filteredDamageCause;
            }

        } catch (error) {
            this.errorReporter.logError(error, 'RemoveDetails-GetDamageCauses');
        }
    }

    async getDamageLocations() {

        try {
            let getDamageLocationCriteria: IDamageLocationCriteria = {
                componentTypeId: this.data.componentTypeId,
                atDate: this.data.sessionDate
            };

            let damageLocations = await this.dataBroker.getDamageLocations(getDamageLocationCriteria);

            this.damageLocationFullList = damageLocations.result;

            if (this.data.selectedDamageLocation) {
                let filteredDamageLocation = this.damageLocationFullList.find(dl => dl.id === this.data.selectedDamageLocation.id);

                if (filteredDamageLocation)
                    this.data.selectedDamageLocation = filteredDamageLocation;
            }

        } catch (error) {
            this.errorReporter.logError(error, 'RemoveDetails-GetDamageLocations');
        }
    }

    async getDamageSources() {

        try {
            let getDamageSourceCriteria: IDamageSourceCriteria = {
                componentTypeId: this.data.componentTypeId,
                atDate: this.data.sessionDate
            };

            let damageSources = await this.dataBroker.getDamageSources(getDamageSourceCriteria);

            this.damageSourceFullList = damageSources.result;

            if (this.data.selectedDamageSource) {
                let filteredDamageSource = this.damageSourceFullList.find(ds => ds.id === this.data.selectedDamageSource.id);

                if (filteredDamageSource)
                    this.data.selectedDamageSource = filteredDamageSource;
            }
        } catch (error) {
            this.errorReporter.logError(error, 'RemoveDetails-GetDamageSources');
        }
    }

    async getStatusFlows() {

        try {
            let getStatusFlowCriteria: IStatusFlowCriteria = {
                equipmentType: this.data.type.toLowerCase() === EquipmentTypeName.assembly ? null : this.data.type,
                excludeNewLife: true,
                statusTypeFrom: StatusTypeName.fitted
            };

            let statusFlows = await this.dataBroker.getStatusFlows(getStatusFlowCriteria);

            this.moveToStatusTypes = statusFlows.result;

            if (this.data.type.toLowerCase() === EquipmentTypeName.assembly) {
                // default to Spare status
                let defaultMoveTo = this.moveToStatusTypes.find(s => s.name.toLowerCase() === StatusTypeName.spare);

                if (defaultMoveTo) {
                    this.data.selectedMoveTo = defaultMoveTo;
                } else {
                    throw new Error('An assembly can only be removed to spare status, but a valid flow to spare status could not be found.');
                }

            } else {

                if (this.data.selectedMoveTo) {
                    let filteredStatusType = this.moveToStatusTypes.find(st => st.id === this.data.selectedMoveTo.id);

                    if (filteredStatusType)
                        this.data.selectedMoveTo = filteredStatusType;
                }
            }

        } catch (error) {
            this.errorReporter.logError(error, 'RemoveDetails-GetStatusFlows');
        }
    }

    // destinations
    async getDestinations() {

        try {

            let sitesCriteria: ISitesCriteria = {
                allowEmptyClientFilter: true,
                includeOtherOption: true,
                excludedSiteIds: [this.ocConfigSvc.user.site.id],
                showAllLocations: false
            };


            sitesCriteria.clientIds = [this.ocConfigSvc.user.client.id];
            sitesCriteria.showAllLocations = true;

            let response = await this.amtCommandQuerySvc.get(this.adminUrls.getSitesCQS, sitesCriteria);

            this.availableDestinations = (response.data || {}).result;

        } catch (error) {
            this.errorReporter.logError(error, 'ChangeStatus-GetDestinations');
        }
    }

    async loadComponentReferenceData() {

        let referenceDataPromises: Promise<any>[] = [];

        referenceDataPromises.push(this.getRemovalReasons());
        referenceDataPromises.push(this.getRepairers());
        referenceDataPromises.push(this.getDamageCauses());
        referenceDataPromises.push(this.getDamageLocations());
        referenceDataPromises.push(this.getDamageSources());
        referenceDataPromises.push(this.getStatusFlows());

        this.getDestinations();
        return Promise.all(referenceDataPromises);
    }
}

class RemoveDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        assembly: '<',
        data: '=',
        removeIndividually: '<',
        processing: '=?',
        attachToApp: '=?',
    };
    public template = tmpl;
    public controller = RemoveDetailsCtrl;
    public controllerAs = 'vm';
}

angular.module('app.directives').component('removeDetails', new RemoveDetailsComponent());