//import angular from 'angular';


angular.module('app.admin')
        .service("sysMaintDamageCauseSvc", [
            "amtXlatSvc", "WindowFactory", "exportSvc", "$q", "amtCommandQuerySvc", 
            function ( 
                amtXlatSvc, WindowFactory, exportSvc, $q, amtCommandQuerySvc
            ) {
                var svc = this; 

                svc.detailComponent = "add-edit-damage-cause";
                svc.module = "DamageCause";
                svc.moduleGroup = "Maintenance";
                svc.webAPIController = svc.module;
                svc.resourceGroup = "damageCause";
                svc.resourceGroupOperationType = "operationType";
                svc.mainPermission = 'Security.Settings.' + svc.moduleGroup + '.' + svc.module;

                svc.getAsync = function (id) {
                    var criteria = {
                        id: id
                    };

                    return amtCommandQuerySvc.post(svc.urls.get, criteria);
                };

                svc.loadDefaultsAsync = function () {
                    return $q(function (resolve) {

                        svc.activeStates = svc.getActiveStates();

                        return resolve(svc.defaults);
                    });
                };

                svc.getActiveStates = function () {
                    return [
                        {
                            key: true,
                            description: amtXlatSvc.xlat('client.activeTrue'),
                            default: true
                        },
                        {
                            key: false,
                            description: amtXlatSvc.xlat('client.activeFalse'),
                            default: false
                        }
                    ];
                };

                svc.urls = {
                    getLookups: baseApiUrl + 'referenceData/get' + svc.module + 's',
                    getLookupsVerb: 'POST',
                    getComponentTypes: baseApiUrl + 'equipment/common/getEquipmentTypes',
                    getComponentTypesVerb: 'POST',
                    getOperationTypes: baseApiUrl + 'referenceData/getOperationTypes',
                    getOperationTypesVerb: 'GET',
                    get: svc.webAPIController + '/get',
                    save: svc.webAPIController + '/save',
                    search: baseApiUrl + svc.webAPIController + '/search',
                    export: svc.webAPIController + '/export'
                };

                svc.export = function (searchCriteria) {
                    exportSvc.exportData(svc.urls.export, searchCriteria, amtXlatSvc.xlat(svc.resourceGroup + '.searchTitle'));
                };

                svc.saveAsync = function (item) {
                    var criteria = {
                        id: item.id,
                        mode: item.mode,
                        name: item.name,
                        description: item.description,
                        componentTypeId: item.componentTypeId,
                        instantFailure: item.instantFailure,
                        tyreBurst: item.tyreBurst,
                        tyreExplosion: item.tyreExplosion,
                        isActive: item.isActive,
                        languageTypeId: item.languageTypeId,
                        applyToAllLanguages: item.applyToAllLanguages
                    };

                    return amtCommandQuerySvc.post(svc.urls.save, criteria);
                };

                svc.addItem = function (cb) {
                    var item = {
                        mode: 'add',
                        instantFailure: false,
                        tyreBurst: false,
                        tyreExplosion: false,
                        isActive: false,
                        applyToAllLanguages: true
                    };

                    svc.openItem(item, cb);
                };

                svc.openItem = function (item, cb) {
                    WindowFactory.openItem({
                        component: svc.detailComponent,
                        caption: amtXlatSvc.xlat(svc.resourceGroup + "." + (item.mode === "add" ? "addItem" : "editItem")),
                        initParams: item,
                        width: 550,
                        onDataChangeHandler: cb,
                        windowRelatedRecordId: item.id
                    });
                };

                svc.openOperationType = function (item) {
                    item.id = item.key;
                    item.name = item.value;
                    WindowFactory.openItem({
                        component: "add-edit-operation-type",
                        caption: amtXlatSvc.xlat(svc.resourceGroupOperationType + ".editItem"),
                        initParams: item,
                        width: 550,
                        windowRelatedRecordId: item.id
                    });
                };

            }
        ])
